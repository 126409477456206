import { applovin_dash } from 'config';
import getQuery from './getQuery';

type DispatchReplaceLocationOptions = {
    updateActiveSection?: boolean;
}

export const dispatchReplaceLocation = (path: string, options?: DispatchReplaceLocationOptions) => {
    const { pathname, search, hash } = new URL(window.location.origin + path);

    const type = options?.updateActiveSection ? 'location_change_replace' : 'location_change_replace_no_section_change';

    window.parent?.postMessage({ type, location: { pathname, search, hash } }, applovin_dash);
}

export const replaceUrl = (path: string, history: any, state?: any) => {
    const query = getQuery();
    let newUrl = new URL(`${window.location.origin}${path}`);

    if (query.key && query.data) newUrl.searchParams[query.key] = query.data;
    
    const newSearchParams = (query?.data) ? 
        ('?' + Object.entries(newUrl.searchParams).map(([key, value]) => `${key}=${value}`).join('&')) :
        '';

    history.replace(`${newUrl.pathname}${newSearchParams}${newUrl.hash}`, state);

    dispatchReplaceLocation(path);
}