import React, { useEffect, FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { isPathStructureIdentical } from 'helpers/utils';
import useStore from 'hooks/useStore';
import { routes } from './AppRoutes';
import Link from './Link';
import arrowRightIcon from 'assets/images/breadcrumb_arrow.svg';
import f from './flex.css';

export type LocationState = {
    publisher?: string
    noBreadcrumbUpdate?: boolean,
    viewAll?: boolean,
}

const Breadcrumbs: FC<{}> = () => {
    const { breadcrumbs, setBreadcrumb, pushBreadcrumb, replaceBreadcrumb, popBreadcrumb } = useStore();

    const history = useHistory();
    const location = useLocation<LocationState>();

    useEffect(() => {
        if (history.action === 'POP' && breadcrumbs.length) {
            popBreadcrumb();

            if (breadcrumbs.length >= 2) return;
        }

        const route = routes.find(({ path }) => isPathStructureIdentical(location.pathname, path));

        if (!route?.title) return

        const { state } = location;

        if (state?.noBreadcrumbUpdate) return;
        
        if (history.action === 'REPLACE' && breadcrumbs.length) return replaceBreadcrumb({ label: state?.publisher || route.title })

        if (state?.publisher) return pushBreadcrumb({ label: state.publisher });
        
        if (route.section && !location.hash && !state?.viewAll) return setBreadcrumb({ label: route.title });
        
        pushBreadcrumb({ label: route.title });
    }, [history, location])

    const onBreadcrumbClick = (event, index: number) => {
        event.preventDefault();

        const goBackTimes = breadcrumbs.length - index - 1;
        
        for (let i = 0; i < goBackTimes; i++) history.goBack();
    }

    return (
        <StyledBar>
            <div className={f.row} style={{margin: '0'}}>
                {breadcrumbs.map(({ pathname, hash, label }, index) => (
                    <div className={f.column} key={`${label}_${index}`}>
                        <div className={f.row} style={{alignItems: 'center'}}> 
                            <StyledBreadcrumb to={`${pathname}${hash}`} onClick={event => onBreadcrumbClick(event, index)}>
                                {label}                           
                            </StyledBreadcrumb>

                            {(index !== (breadcrumbs.length - 1)) && <img style={{margin: 'auto 6px'}} src={arrowRightIcon}/>}
                        </div>
                    </div>
                ))}
            </div>
        </StyledBar>
    );
}

const StyledBreadcrumb = styled(Link)`
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 13px;
    color: ${({ theme }) => theme.colors.tertiaryText} !important;

    &:last-child {
        color: black !important;
        pointer-events: none;
    }
`;

const StyledBar = styled.div`
    min-height: 48px;
    line-height: 48px;
    padding: 0 0 0 16px;
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.colors.silverLight};
`;

export default Breadcrumbs;
