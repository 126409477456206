import React, { FC, MouseEvent } from 'react';
import { Link as ReactRouterLink, useHistory, LinkProps as ReactRouterLinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { env, applovin_dash } from 'config';
import { dispatchReplaceLocation } from 'utils/thirdPartyMessageDispatcher';

export type LinkProps = ReactRouterLinkProps & {
    disabled?: boolean;
}

const Link: FC<LinkProps> = ({ to, disabled, onClick, children, ...otherProps }) => {
    const history = useHistory();
    
    const externalUrl = env === 'local' ? to?.pathname || to : `${applovin_dash}/o/adreview${to?.pathname || to}`;

    const onLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
        if (event.ctrlKey || event.metaKey) return;

        event.preventDefault();

        if (disabled) return;

        dispatchReplaceLocation(to?.pathname || to);

        if (onClick) return onClick(event);

        history.push(to);
    }

    return (
        <StyledLink to={{ pathname: externalUrl }} onClick={onLinkClick} {...otherProps}>
            {children}
        </StyledLink>
    )
}

const StyledLink = styled(ReactRouterLink)`
    text-decoration: none;
    color: inherit;
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};

    :hover, :active, :visited {
        color: inherit;
    }
`;

export default Link;